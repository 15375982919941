import { Box, Container, Icon, TextField, Typography } from "@material-ui/core";
import React, { useContext } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { TimestampVerifierContext } from "../../context/TimestampVerifierProvider";
import BFAv2ResultInfo from "../ResultInfo/BFAv2ResultInfo";

const TimestampVerifierResult = (props) => {
  const intl = useIntl();
  const { currentTimestampAuthority, hash, manualHash } = useContext(
    TimestampVerifierContext
  );

  if (!props.result) {
    return <></>;
  }

  if (!props.result.stamped) {
    return (
      <Box
        display="flex"
        alignItems="center"
        flexDirection="column"
        overflow="auto"
      >
        <Container>
          <Typography align="center">
            <Icon fontSize="large" color="action">
              event_busy
            </Icon>
          </Typography>
          <Typography variant="h4" color="textSecondary" align="center">
            <FormattedMessage
              id="timestamp-verifier.timestamp-not-found"
              defaultMessage="Timestamp not found"
            />
          </Typography>
          <Box mt={2}>
            <Typography variant="body2" color="textSecondary" align="center">
              <FormattedMessage
                id="timestamp-verifier.hash-wasnt-stamped"
                defaultMessage="Hash wasn't stamped in {timestampAuthorityDescription}."
                values={{
                  timestampAuthorityDescription:
                    currentTimestampAuthority.description,
                }}
              />
            </Typography>
          </Box>
          <Box mt={4}>
            <TextField
              value={hash ?? manualHash ?? ""}
              variant="outlined"
              label={intl.formatMessage({
                id: "timestamp-verifier.hash-verified",
                defaultMessage: "Hash verified",
              })}
              fullWidth
              disabled
            />
          </Box>
        </Container>
      </Box>
    );
  }

  return (
    <Box
      display="flex"
      alignItems="center"
      flexDirection="column"
      overflow="auto"
    >
      <Container>
        <Typography align="center">
          <Icon fontSize="large" color="primary">
            event_available
          </Icon>
        </Typography>
        <Typography variant="h4" color="primary" align="center">
          <FormattedMessage
            id="timestamp-verifier.timestamp-found"
            defaultMessage="Timestamp found!"
          />
        </Typography>
        <Box mt={2}>
          <Typography variant="body2" color="textSecondary" align="center">
            <FormattedMessage
              id="timestamp-verifier.timestamp-was-stamped"
              defaultMessage="Hash was stamped on {timestampDate} {timestampTime} in {timestampAuthorityDescription}."
              values={{
                timestampDate: intl.formatDate(props.result.timestamp * 1000),
                timestampTime: intl.formatTime(props.result.timestamp * 1000),
                timestampAuthorityDescription:
                  currentTimestampAuthority.description,
              }}
            />
          </Typography>
        </Box>
        <Box mt={4}>
          <TextField
            value={hash ?? manualHash ?? ""}
            variant="outlined"
            label={intl.formatMessage({
              id: "timestamp-verifier.hash-verified",
              defaultMessage: "Hash verified",
            })}
            fullWidth
            disabled
          />
        </Box>
        <Box mt={4}>
          {currentTimestampAuthority.id === "bfav2" && props.result.info && (
            <BFAv2ResultInfo info={props.result.info} />
          )}
        </Box>
      </Container>
    </Box>
  );
};

export default TimestampVerifierResult;
