import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const useStyle = makeStyles((theme) => ({
  root: {
    "& .MuiListItem-root": {
      display: "block",
    },
  },
}));

const EndorsementDetailList = (props) => {
  const {
    endorsement,
    enableVerifyTimestampButton,
    handleVerifyTimestampClick,
    ...rest
  } = props;
  const classes = useStyle();
  const intl = useIntl();

  return (
    <List {...rest} classes={classes}>
      {endorsement.issuer?.name && (
        <ListItem divider={true} disableGutters disablePadding>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage
              defaultMessage="Issued By"
              id="badges-validator:issued-by-text"
            />
          </Typography>
          <Typography variant="body1">{endorsement.issuer.name}</Typography>
        </ListItem>
      )}
      {endorsement.issuedOn && (
        <ListItem divider={true} disableGutters disablePadding>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage
              id="badges-validator:issued-on-text"
              defaultMessage="Issued On"
            />
          </Typography>
          <Typography variant="body1">
            <FormattedMessage
              id="badges-validator:issued-on-value"
              defaultMessage="{issueDate} {issueTime}"
              values={{
                issueDate: intl.formatDate(endorsement.issuedOn),
                issueTime: intl.formatTime(endorsement.issuedOn),
              }}
            />
          </Typography>
        </ListItem>
      )}
      {endorsement.claim?.endorsementComment && (
        <ListItem divider={true} disableGutters disablePadding>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage
              defaultMessage="Claim"
              id="badges-validator:claim-text"
            />
          </Typography>
          <Typography>{endorsement.claim.endorsementComment}</Typography>
        </ListItem>
      )}
      {endorsement.claim?.evidence && (
        <ListItem divider={true} disableGutters disablePadding>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage
              defaultMessage="Evidence"
              id="badges-validator:evidence-text"
            />
          </Typography>
          <Typography>{endorsement.claim.evidence}</Typography>
        </ListItem>
      )}
      {enableVerifyTimestampButton && endorsement.timestamp && (
        <ListItem divider={true} disableGutters>
          <Button
            size="small"
            variant="outlined"
            color="primary"
            onClick={() =>
              handleVerifyTimestampClick(
                endorsement.timestamp?.timestampAuthority,
                endorsement.timestamp?.hash
              )
            }
          >
            <Typography>
              <FormattedMessage
                defaultMessage="Verify Timestamp"
                description="Verify Timestamp button text"
                id="badges-validator:verify-timestamp-button"
              />
            </Typography>
          </Button>
        </ListItem>
      )}
    </List>
  );
};

export default EndorsementDetailList;
