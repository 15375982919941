import { defineMessages } from "react-intl";

class TimestampsService {
  constructor(axiosInstance, verifyApiURL, intl) {
    this.axiosInstance = axiosInstance;
    this.verifyApiURL = verifyApiURL;
    this.intl = intl;
  }

  errors = defineMessages({
    "Must send a valid SHA-256 digest.": {
      id: "timestamp-verifier:timestamps-service-invalid-digest",
      defaultMessage: "Must send a valid SHA-256 digest.",
    },
  });

  verifyTimestamp = async (timestampType, timestampPayload) => {
    return this.axiosInstance
      .post(this.verifyApiURL, {
        timestampPayload: timestampPayload,
        timestampType: timestampType,
      })
      .then((response) => {
        if (!response || !response.data || !response.data.response) {
          throw Error(
            this.intl.formatMessage({
              id: "timestamp-verifier:timestamps-service-unexpected-error",
              defaultMessage: "An unexpected error ocurred. Please try again.",
            })
          );
        }
        return response.data.response;
      })
      .catch((error) => {
        if (error.response?.status === 422) {
          let errorMessage = this.errors[
            error.response.data["hydra:description"]
          ]
            ? this.intl.formatMessage(
                this.errors[error.response.data["hydra:description"]]
              )
            : error.response.data["hydra:description"];
          throw Error(errorMessage);
        }
        throw Error(
          this.intl.formatMessage({
            id: "timestamp-verifier:timestamps-service-unexpected-error",
            defaultMessage: "An unexpected error ocurred. Please try again.",
          })
        );
      });
  };
}

export default TimestampsService;
