import React from "react";
import { IntlProvider } from "react-intl";
import BadgesValidatorCard from "./BadgesValidatorCard";
import * as languages from "../../languages";

const BadgesValidator = (props) => {
  const locale = props.locale ?? "en";
  return (
    <IntlProvider locale={locale} key={locale} messages={languages[locale]}>
      <BadgesValidatorCard
        axios={props.axios}
        validationApiURL={props.validationApiURL}
        verifyApiURL={props.verifyApiURL}
        badgeText={props.badgeText}
        fallbackImage={props.fallbackImage}
      />
    </IntlProvider>
  );
};

export default BadgesValidator;
