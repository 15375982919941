import {
  Box,
  Button,
  CircularProgress,
  Icon,
  makeStyles,
  Typography,
} from "@material-ui/core";
import React from "react";
import { FormattedMessage, IntlProvider, useIntl } from "react-intl";
import axios from "axios";
import {
  TimestampVerifierContext,
  TimestampVerifierProvider,
} from "../../context/TimestampVerifierProvider";
import TimestampVerifierForm from "./TimestampVerifierForm";
import TimestampVerifierResult from "./TimestampVerifierResult";
import { Alert } from "@material-ui/lab";
import * as languages from "../../languages";

const useStyle = makeStyles({
  dropzone: {
    borderStyle: "dashed",
  },
});

const TimestampVerifier = (props) => {
  const classes = useStyle();
  const intl = useIntl();
  const axiosInstance = props.axios ?? axios.create();
  const verifyApiURL = props.verifyApiURL ?? "";
  const showVerifyAnotherTimestampButton =
    props.showVerifyAnotherTimestampButton ?? true;
  const locale = props.locale ?? "en";

  return (
    <IntlProvider locale={locale} key={locale} messages={languages[locale]}>
      <TimestampVerifierProvider
        axiosInstance={axiosInstance}
        verifyApiURL={verifyApiURL}
        verifying={props.verifying}
        hash={props.hash}
        timestampAuthority={props.timestampAuthority}
      >
        <TimestampVerifierContext.Consumer>
          {(value) => (
            <Box>
              {value.error && (
                <Box mb={2}>
                  <Alert color="error" icon={<Icon>error</Icon>}>
                    {value.error}
                  </Alert>
                </Box>
              )}
              {value.verifying && (
                <Box display="flex" flexDirection="column" alignItems="center">
                  <Box mt={2}>
                    <CircularProgress />
                  </Box>
                  <Box mt={2}>
                    <Typography variant="body2" color="textSecondary">
                      <FormattedMessage
                        id="timestamp-verifier.verifying-timestamp"
                        defaultMessage="Verifying Timestamp"
                      />
                    </Typography>
                  </Box>
                </Box>
              )}
              {!value.verifying && !value.verificationResult && (
                <TimestampVerifierForm />
              )}
              {!value.verifying && value.verificationResult && (
                <>
                  <TimestampVerifierResult result={value.verificationResult} />
                  {showVerifyAnotherTimestampButton && (
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection="column"
                      alignItems="center"
                    >
                      <Button
                        variant="text"
                        onClick={() => {
                          value.clearVerification();
                        }}
                      >
                        <FormattedMessage
                          id="timestamp-verifier.verify-another-timestamp"
                          defaultMessage="Verify another timestamp"
                        />
                      </Button>
                    </Box>
                  )}
                </>
              )}
            </Box>
          )}
        </TimestampVerifierContext.Consumer>
      </TimestampVerifierProvider>
    </IntlProvider>
  );
};

export default TimestampVerifier;
