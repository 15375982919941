import {
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";

const BadgesValidatorRecipient = (props) => {
  const recipientTypeIcon = (recipientType) => {
    let icon;
    switch (recipientType) {
      case "email":
        icon = "alternate_email";
        break;
      case "url":
        icon = "http";
        break;
      case "telephone":
        icon = "phone";
        break;
      default:
        icon = "error";
    }

    return <Icon>{icon}</Icon>;
  };

  if (!props.recipient) {
    return "";
  }

  return (
    <>
      <List>
        <ListItem>
          <ListItemIcon>{recipientTypeIcon(props.recipient.type)}</ListItemIcon>
          <ListItemText disableTypography={true}>
            <Typography variant="button">{props.recipient.identity}</Typography>
          </ListItemText>
        </ListItem>
      </List>
    </>
  );
};

export default BadgesValidatorRecipient;
