import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@material-ui/core";
import React, { useState } from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormattedMessage, useIntl } from "react-intl";
import EndorsementDetailList from "./Endorsement/EndorsementDetailList";
import EndorsementTimestampVerifyDialog from "./Endorsement/EndorsementTimestampVerifyDialog";

const BadgesValidatorEndorsements = (props) => {
  const intl = useIntl();
  const [verify, setVerify] = useState({
    open: false,
    timestampAuthority: null,
    hash: null,
  });

  const handleVerifyTimestampClick = (timestampAuthority, timestampPayload) => {
    setVerify({
      open: true,
      timestampAuthority: timestampAuthority,
      hash: timestampPayload,
    });
  };

  const handleDialogCloseClick = () => {
    setVerify({ open: false, timestampAuthority: null, hash: null });
  };

  if (!props.endorsement) {
    return "";
  }

  let endorsements =
    props.endorsement instanceof Array
      ? props.endorsement
      : [props.endorsement];

  if (!endorsements.length) {
    return "";
  }

  return (
    <>
      {endorsements.map((endorsement, pos) => (
        <Accordion key={endorsement.id ?? endorsement}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography>
              <FormattedMessage
                id="badges-validator:endorsement-title"
                defaultMessage="Endorsement #{pos}"
                values={{
                  pos: pos + 1,
                }}
              />
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            {!endorsement.id ? (
              <Typography>{endorsement}</Typography>
            ) : (
              <EndorsementDetailList
                endorsement={endorsement}
                handleVerifyTimestampClick={handleVerifyTimestampClick}
                enableVerifyTimestampButton={props.verifyApiURL && props.axios}
                disablePadding
              />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
      <EndorsementTimestampVerifyDialog
        axios={props.axios}
        verifyApiURL={props.verifyApiURL}
        handleDialogCloseClick={handleDialogCloseClick}
        timestampAuthority={verify.timestampAuthority}
        hash={verify.hash}
        open={verify.open}
        maxWidth="sm"
        fullWidth
      />
    </>
  );
};

export default BadgesValidatorEndorsements;
