import { FormControl, MenuItem, Select } from "@material-ui/core";
import React, { useContext } from "react";
import TimestampAuthorities from "../../configs/TimestampAuthorities";
import { TimestampVerifierContext } from "../../context/TimestampVerifierProvider";

const TimestampVerifierAutoritySelector = () => {
  const { setTimestampAuthority, currentTimestampAuthority } = useContext(
    TimestampVerifierContext
  );

  return (
    <FormControl fullWidth>
      <Select
        variant="outlined"
        value={currentTimestampAuthority ? currentTimestampAuthority.id : null}
        onChange={(evt) => {
          setTimestampAuthority(evt.target.value);
        }}
      >
        {TimestampAuthorities.map((timestampAuthority) => (
          <MenuItem key={timestampAuthority.id} value={timestampAuthority.id}>
            {timestampAuthority.description}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default TimestampVerifierAutoritySelector;
