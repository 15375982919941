import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  Typography,
} from "@material-ui/core";
import React from "react";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { FormattedMessage } from "react-intl";
import fallbackBadgeImage from "../../fallbackBadgeImage.svg";

const BadgesValidatorBadgeClass = (props) => {
  if (!props.badge) {
    return "";
  }

  return (
    <Grid container direction="row">
      <Grid item md={3}>
        <img
          src={props.badge.image}
          alt={props.badge.name}
          width="240"
          onError={(e) =>
            (e.currentTarget.src = props.fallbackImage || fallbackBadgeImage)
          }
        />
      </Grid>
      <Grid item xs>
        <Typography variant="h6">{props.badge.name}</Typography>
        <Typography variant="body1">{props.badge.description}</Typography>
        {props.badge.criteria && (
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography>
                <FormattedMessage
                  id="badges-validator:criteria-subtitle"
                  defaultMessage="Criteria"
                  description="Criteria subtitle"
                />
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>
                {typeof props.badge.criteria === "string"
                  ? props.badge.criteria
                  : props.badge.criteria.narrative ?? ""}
              </Typography>
            </AccordionDetails>
          </Accordion>
        )}
      </Grid>
    </Grid>
  );
};

export default BadgesValidatorBadgeClass;
