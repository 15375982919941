import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  Typography,
} from "@material-ui/core";
import { TimestampVerifier } from "matedapps-timestamp-verifier";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";

const EndorsementTimestampVerifyDialog = (props) => {
  const {
    verifyApiURL,
    axios,
    handleDialogCloseClick,
    timestampAuthority,
    hash,
    ...rest
  } = props;

  const intl = useIntl();

  return (
    <Dialog {...rest}>
      <DialogTitle>
        <Box display="flex" flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">
            <FormattedMessage
              defaultMessage="Verify Timestamp"
              description="Verify Timestamp button text"
              id="badges-validator:verify-timestamp-button"
            />
          </Typography>
          <IconButton size="small" onClick={handleDialogCloseClick}>
            <Icon>close</Icon>
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <TimestampVerifier
          verifyApiURL={verifyApiURL}
          axios={axios}
          showVerifyAnotherTimestampButton={false}
          timestampAuthority={timestampAuthority}
          hash={hash}
          locale={intl.locale}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDialogCloseClick} p={1}>
          <FormattedMessage
            defaultMessage="Close"
            description="Close button text"
            id="badges-validator:dialog-close-button"
          />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EndorsementTimestampVerifyDialog;
