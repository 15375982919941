import {
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";

const BadgesValidatorIssuer = (props) => {
  if (!props.issuer) {
    return "";
  }

  return (
    <>
      <List>
        <ListItem>
          <ListItemText disableTypography={true}>
            <Typography variant="button">{props.issuer.name}</Typography>
          </ListItemText>
        </ListItem>
      </List>
      <Divider />
      <Grid container direction="row">
        <Grid item sm={3} container justify="center" alignContent="center">
          {props.issuer.image ? (
            <img
              src={props.issuer.image}
              alt={props.issuer.name}
              width="100%"
              style={{ maxWidth: "100%", maxHeight: "100%" }}
            />
          ) : (
            <Icon color="action" style={{ fontSize: 64 }}>
              business
            </Icon>
          )}
        </Grid>
        <Grid item xs>
          <List>
            {props.issuer.description && (
              <ListItem>
                <ListItemIcon>
                  <Icon>info</Icon>
                </ListItemIcon>
                <ListItemText primary={props.issuer.description} />
              </ListItem>
            )}
            {props.issuer.email && (
              <ListItem>
                <ListItemIcon>
                  <Icon>alternate_email</Icon>
                </ListItemIcon>
                <ListItemText primary={props.issuer.email} />
              </ListItem>
            )}
            {props.issuer.telephone && (
              <ListItem>
                <ListItemIcon>
                  <Icon>phone</Icon>
                </ListItemIcon>
                <ListItemText primary={props.issuer.telephone} />
              </ListItem>
            )}
            {props.issuer.url && (
              <ListItem>
                <ListItemIcon>
                  <Icon>link</Icon>
                </ListItemIcon>
                <ListItemText primary={props.issuer.url} />
              </ListItem>
            )}
          </List>
        </Grid>
      </Grid>
    </>
  );
};

export default BadgesValidatorIssuer;
