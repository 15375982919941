import React, { createContext, useEffect, useState } from "react";
import TimestampsService from "../services/TimestampsService";
import TimestampAuthorities from "../configs/TimestampAuthorities";
import { useIntl } from "react-intl";

const getTimestampAuthorityById = (timestampAuthorityId) => {
  let tsa = null;
  TimestampAuthorities.map((timestampAuthority) => {
    if (timestampAuthority.id === timestampAuthorityId) {
      tsa = timestampAuthority;
    }
  }, tsa);
  return tsa;
};

const TimestampVerifierContext = createContext(null);

const TimestampVerifierProvider = (props) => {
  const intl = useIntl();

  const service = new TimestampsService(
    props.axiosInstance,
    props.verifyApiURL,
    intl
  );
  const [hash, setHash] = useState(props.hash ?? null);
  const [manualHash, setManualHash] = useState(null);
  const [currentTimestampAuthority, setCurrentTimestampAuthority] = useState(
    props.timestampAuthority
      ? getTimestampAuthorityById(props.timestampAuthority)
      : TimestampAuthorities[0]
  );
  const [verificationResult, setVerificationResult] = useState(null);
  const [verifying, setVerifying] = useState(props.verifying ?? false);
  const [error, setError] = useState(null);

  const handleFileDrop = (acceptedFiles) => {
    let fileContents = [];
    acceptedFiles.map((file) => {
      const reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");
      reader.onload = () => {
        const binaryStr = reader.result;
        fileContents.push(binaryStr);

        if (fileContents.length === acceptedFiles.length) {
          verifyHash(currentTimestampAuthority.calculateHash(fileContents[0]));
        }
      };

      return reader.readAsText(file);
    });
  };

  const verifyHash = (fileHash) => {
    fileHash && setHash(fileHash);

    if (!currentTimestampAuthority) {
      setError(
        intl.formatMessage({
          id: "timestamp-verifier:timestamp-authority-required",
          defaultMessage: "Please select a timestamp authority.",
        })
      );
      return;
    }

    if (!fileHash && !hash && !manualHash) {
      setError(
        intl.formatMessage({
          id: "timestamp-verifier:hash-required",
          defaultMessage: "Please select a file or enter a hash to verify.",
        })
      );
      return;
    }

    setError(null);
    setVerifying(true);
    service
      .verifyTimestamp(
        currentTimestampAuthority.id,
        fileHash ?? hash ?? manualHash
      )
      .then((result) => {
        setVerifying(false);
        setVerificationResult(result);
      })
      .catch((error) => {
        setVerifying(false);
        setError(error.message);
      });
  };

  const clearVerification = () => {
    setHash(null);
    setManualHash(null);
    setVerificationResult(null);
    setVerifying(false);
    setError(null);
  };

  const setTimestampAuthority = (timestampAuthorityId) => {
    setCurrentTimestampAuthority(
      getTimestampAuthorityById(timestampAuthorityId)
    );
  };

  useEffect(() => {
    props.timestampAuthority && setTimestampAuthority(props.timestampAuthority);
    props.hash && verifyHash(props.hash);

    return () => {
      clearVerification();
    };
  }, [props.timestampAuthority, props.hash]);

  return (
    <TimestampVerifierContext.Provider
      value={{
        hash,
        manualHash,
        setManualHash,
        currentTimestampAuthority,
        setTimestampAuthority,
        handleFileDrop,
        verifyHash,
        verifying,
        verificationResult,
        clearVerification,
        error,
      }}
    >
      {props.children}
    </TimestampVerifierContext.Provider>
  );
};

export { TimestampVerifierProvider, TimestampVerifierContext };
