import {
  Box,
  Button,
  Divider,
  Icon,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useContext } from "react";
import Dropzone from "react-dropzone";
import { FormattedMessage, useIntl } from "react-intl";
import { TimestampVerifierContext } from "../../context/TimestampVerifierProvider";
import TimestampVerifierAutoritySelector from "./TimestampVerifierAutoritySelector";

const useStyle = makeStyles({
  dropzone: {
    borderStyle: "dashed",
  },
});

const TimestampVerifierForm = () => {
  const classes = useStyle();
  const intl = useIntl();
  const { manualHash, setManualHash, handleFileDrop, verifyHash } = useContext(
    TimestampVerifierContext
  );

  const handleVerifyButtonClick = (evt) => {
    verifyHash();
  };

  return (
    <>
      <Box mb={3}>
        <Box mb={1}>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage
              id="timestamp-verifier.select-timestamp-authority"
              defaultMessage="Select Timestamp Authority"
            />
          </Typography>
        </Box>
        <TimestampVerifierAutoritySelector />
      </Box>
      <Box mb={2}>
        <Box mb={1}>
          <Typography variant="caption" color="textSecondary">
            <FormattedMessage
              id="timestamp-verifier.select-file"
              defaultMessage="Select a file to check his timestamp"
            />
          </Typography>
        </Box>
        <Dropzone onDrop={(acceptedFiles) => handleFileDrop(acceptedFiles)}>
          {({ getRootProps, getInputProps, isDragActive }) => (
            <Box
              display="grid"
              p={2}
              border={1}
              borderRadius={3}
              justifyItems="center"
              className={classes.dropzone}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <Icon color="action">upload</Icon>
              <Typography variant="body1" color="textSecondary">
                <FormattedMessage
                  id="timestamp-verifier.drag-drop-text"
                  defaultMessage="Drag 'n' drop a file here, or click to select one."
                />
              </Typography>
            </Box>
          )}
        </Dropzone>
      </Box>
      <Box mb={2}>
        <Divider />
      </Box>
      <Typography variant="caption" color="textSecondary">
        <FormattedMessage
          id="timestamp-verifier.paste-hash"
          defaultMessage="Or manually paste your hash"
        />
      </Typography>
      <Box mt={1} mb={2} display="flex" flexDirection="row">
        <Box flexGrow={1}>
          <TextField
            variant="outlined"
            value={manualHash ?? ""}
            size="small"
            onChange={(evt) => {
              setManualHash(evt.target.value);
            }}
            placeholder={intl.formatMessage({
              id: "timestamp-verifier.hash",
              defaultMessage: "Hash to verify",
            })}
            fullWidth
          />
        </Box>
        <Box flexShrink={1} ml={1}>
          <Button
            disabled={manualHash ? false : true}
            color="primary"
            size="medium"
            variant="contained"
            onClick={handleVerifyButtonClick}
          >
            <FormattedMessage
              id="timestamp-verifier.verify"
              defaultMessage="Verify"
            />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default TimestampVerifierForm;
