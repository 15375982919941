import sha256 from "crypto-js/sha256";
import BFALogo from "../icons/bfa.svg";

const TimestampAuthorities = [
  {
    id: "bfav2",
    description: "Blockchain Federal Argentina Sello 2.0",
    logo: BFALogo,
    calculateHash: (fileContents) => {
      return sha256(fileContents).toString();
    },
  },
];

export default TimestampAuthorities;
