import { Box, Paper, Typography } from "@material-ui/core";
import React from "react";
import { FormattedMessage } from "react-intl";

const BFAv2ResultInfo = (props) => {
  if (!props.info || !props.info.stamps) {
    return <></>;
  }

  return (
    <>
      {props.info.stamps.map((stamp) => (
        <Box mb={1}>
          <Paper variant="outlined">
            <Box p={1}>
              <Box mb={1}>
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage
                    id="timestamp-verifier.stamped-by"
                    defaultMessage="Stamped By"
                  />
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  {stamp.whostamped}
                </Typography>
              </Box>
              <Box mb={1}>
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage
                    id="timestamp-verifier.block-number"
                    defaultMessage="Block Number"
                  />
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  {stamp.blocknumber}
                </Typography>
              </Box>
              <Box>
                <Typography variant="caption" color="textSecondary">
                  <FormattedMessage
                    id="timestamp-verifier.block-timestamp"
                    defaultMessage="Block Timestamp"
                  />
                </Typography>
                <Typography variant="body2" noWrap={true}>
                  {stamp.blocktimestamp}
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>
      ))}
    </>
  );
};

export default BFAv2ResultInfo;
